<template>
  <div class="data-rule-wrap flex-column">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$basicSystemConfigSjqxSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" ></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <div class="bg-white" style="height: 100%;">
        <el-table ref="mainTable" height="calc(100% - 52px)" :key='tableKey' :data="list"  border fit highlight-current-row  style="width: 100%;" @row-click="rowClick" @selection-change="handleSelectionChange">
          <!-- 通用表格循环组件  -->
          <general-table-column :headerList='headerList' :hasSelection='true' :hasOperation="true" @handleUpdate="handleUpdate" @handleModifyStatus='handleModifyStatus'></general-table-column>
        </el-table>
        <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="handleCurrentChange" />
      </div>
      <el-dialog  modal-append-to-body   v-el-drag-dialog :destroy-on-close="true" class="dialog-mini rule-form-dialog special_sjqx" width="850px" :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
        <el-form :rules="rules" ref="dataForm" :model="temp" label-position="right" label-width="100px">
          <el-row>
            <el-col :span="12">
              <el-form-item label-width="60px" size="small" :label="'数据ID'" prop="id">
                <el-input v-model="temp.id" :disabled="true" placeholder="系统自动处理"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label-width="150px" size="small" :label="'资源标识（模块编号）'" prop="sourceCode">
                <el-select class="sellect-operation" size="mini" filterable  clearable  v-model="temp.sourceCode" @change="handleSourceCode">
                  <el-option v-for="item in sourceModules" :key="item.id" :label="item.name" :value="item.code"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <add-rule v-if="temp.sourceCode" :dataSource="temp.privilegeRules" :propertyDatas="propertyDatas" ref="addRule"></add-rule>
          <el-row>
            <el-col :span="6">
              <el-form-item label-width="60px" size="small" :label="'排序号'">
                <el-input-number v-model="temp.sortNo" :min="0" :max="10" ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item size="small" :label="'是否可用'" prop="enable">
                <el-switch v-model="temp.enable"></el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item size="small" :label="'权限描述'" prop="description">
                <el-input v-model="temp.description" maxlength="50" show-word-limit ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item hidden size="small" :label="'权限规则'" prop="privilegeRules">
            <el-input v-model="temp.privilegeRules" readonly style="padding-right: 26px;"></el-input>
            <span style="position: absolute;right: 0;top:0;bottom:0;display:inline-block;background: #fff;border: 1px solid #DCDFE6;padding: 0 10px;border-radius: 0 4px 4px 0;cursor:pointer;" @click="dialogRuleVisible = true;"><i class="el-icon-more"></i></span>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button size="mini" @click="dialogFormVisible = false">取消</el-button>
          <el-button size="mini" v-if="dialogStatus=='create'" type="primary" @click="createData">确认</el-button>
          <el-button size="mini" v-else type="primary" @click="updateData">确认</el-button>
        </div>
      </el-dialog>
    </div>
  </div>

</template>

<script>
  
  import * as dataPrivilegeRules from '@/api/dataprivilegerules'
  import * as apiModules from '@/api/modules'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import Pagination from '@/components/Pagination'
  import elDragDialog from '@/directive/el-dragDialog'
  import addRule from './addRule'
  import { mapGetters, mapActions } from 'vuex'
  import extend from "@/extensions/delRows.js"
  import generalTableColumn from '@/components/generalTableColumn'
  export default {
    name: 'dataPrivilegeRule',
    components: {
      Sticky,
      Pagination,
      addRule,
      generalTableColumn,
    },
    mixins: [extend],
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        propertyDatas: [],
        multipleSelection: [], // 列表c eckbox选中的值
        tableKey: 0,
        list: [],
        total: 0,
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          key: undefined,
          appId: undefined
        },
        temp: {
          id: '', // 数据ID
          sourceCode: '', // 资源标识（模块编号）
          subSourceCode: '', // 二级资源标识
          description: '', // 权限描述
          sortNo: '', // 排序号
          privilegeRules: '', // 权限规则
          enable: true, // 是否可用
          extendInfo: '' // 其他信息,防止最后加逗号，可以删除
        },
        dialogFormVisible: false,
        dialogStatus: '',
        textMap: {
          update: '编辑',
          create: '添加'
        },
        rules: {
          appId: [{
            required: true,
            message: '必须选择一个应用',
            trigger: 'change'
          }],
          name: [{
            required: true,
            message: '名称不能为空',
            trigger: 'blur'
          }]
        },
        headerList: []
      }
    },
    filters: {
      filterInt(val) {
        switch (val) {
          case null:
            return ''
          case 1:
            return '状态1'
          case 2:
            return '状态2'
          default:
            return '默认状态'
        }
      },
      statusFilter(disable) {
        const statusMap = {
          false: 'color-success',
          true: 'color-danger'
        }
        return statusMap[disable]
      }
    },
    computed: {
      ...mapGetters({
        modules: 'checkModules'
      }),
      sourceModules() {
        if (this.modules.length <= 0) return
        return this.modules.filter(item => item.code)
      }
    },
    created() {
      
    },
    activated() {
      this.getList()
    },
    methods: {
      ...mapActions({
        GetModules: 'getCheckModules'
      }),
      rowClick(row) {
        this.$refs.mainTable.clearSelection()
        this.$refs.mainTable.toggleRowSelection(row)
      },
      handleSelectionChange(val) {
        this.multipleSelection = val
      },
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnAdd':
            this.handleCreate()
            break
          case 'btnEdit':
            if (this.multipleSelection.length !== 1) {
              this.$message({
                message: '只能选中一个进行编辑',
                type: 'error'
              })
              return
            }
            this.handleUpdate(this.multipleSelection[0])
            break
          case 'btnDel':
            if (this.multipleSelection.length < 1) {
              this.$message({
                message: '至少删除一个',
                type: 'error'
              })
              return
            }
            this.handleDelete(this.multipleSelection)
            break
          default:
            break
        }
      },
      getList() {
        dataPrivilegeRules.getList(this.listQuery).then(response => {
          if(response.code == '200'){
            response.columnHeaders.forEach((item) => {
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.headerList = response.columnHeaders.filter(u => u.browsable)
            this.list = response.data
            this.total = response.count
          }else{
            this.headerList =[]
            this.list =[]
          }
          this.$nextTick(() => {
            this.$refs.mainTable.doLayout();
          });
        }).catch(()=>{
          this.headerList =[]
          this.list =[]
        })
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      handleSizeChange(val) {
        this.listQuery.limit = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      handleModifyStatus(row) { // 模拟修改状态
        var data={
          id:row.id
        }
        this.$store.commit("updataListLoading",true)
        dataPrivilegeRules.tyDisable(data).then(response => {
          if(response.code == '200'){
            this.getList()
            this.$message({
              message: response.message,
              type: 'success'
            })
          }else{
            this.$message({
              message: response.message,
              type: 'warning'
            })
          }
          this.$store.commit("updataListLoading",false)
        }).catch(()=>{
          this.$store.commit("updataListLoading",false)
        })
      },
      resetTemp() {
        this.temp = {
          id: '',
          sourceCode: '',
          subSourceCode: '',
          description: '',
          sortNo: '',
          privilegeRules: '',
          enable: true,
          createTime: '',
          createUserId: '',
          createUserName: '',
          updateTime: '',
          updateUserId: '',
          updateUserName: '',
          extendInfo: ''
        }
      },
      handleCreate() { // 弹出添加框
        this.resetTemp()
        this.dialogStatus = 'create'
        this.dialogFormVisible = true
        this.modules.length <= 0 && this.GetModules()
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
      },
      createData() { // 保存提交
        this.temp.privilegeRules = this.temp.sourceCode && JSON.stringify(this.$refs.addRule.saveRule()) || ''
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.$store.commit("updataListLoading",true)
            dataPrivilegeRules.add(this.temp).then(() => {
              this.list.unshift(this.temp)
              this.dialogFormVisible = false
              this.$notify({
                title: '成功',
                message: '创建成功',
                type: 'success',
                duration: 2000
              })
              this.$store.commit("updataListLoading",false)
            }).catch(()=>{
              this.$store.commit("updataListLoading",false)
            })
          }
        })
      },
      handleUpdate(row) { // 弹出编辑框
        this.temp = Object.assign({}, row) // copy obj
        this.dialogStatus = 'update'
        this.dialogFormVisible = true
        this.modules.length <= 0 && this.GetModules()
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
        this.loadProperties()
      },
      loadProperties() {
        this.temp.sourceCode && apiModules.getProperties(this.temp.sourceCode).then((res) => {
          this.propertyDatas = res.result
        }).catch(() => {
          this.propertyDatas = []
        })
      },
      updateData() { // 更新提交
        this.temp.privilegeRules = JSON.stringify(this.$refs.addRule.saveRule())
        this.temp.updateTime = '刚刚'
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            const tempData = Object.assign({}, this.temp)
            this.$store.commit("updataListLoading",true)
            dataPrivilegeRules.update(tempData).then(() => {
              for (const v of this.list) {
                if (v.id === this.temp.id) {
                  const index = this.list.indexOf(v)
                  this.list.splice(index, 1, this.temp)
                  break
                }
              }
              this.dialogFormVisible = false
              this.$notify({
                title: '成功',
                message: '更新成功',
                type: 'success',
                duration: 2000
              })
              this.$store.commit("updataListLoading",false)
            }).catch(()=>{
              this.$store.commit("updataListLoading",false)
            })
          }
        })
      },
      handleDelete(rows) { // 多行删除
        this.delrows(dataPrivilegeRules, rows)
      },
      handleSourceCode() {
        this.loadProperties()
        this.temp.privilegeRules = ''
      },
    },
    watch:{
      //处理通过tags关闭页面多选不清空的问题
      '$route'(){
        if(this.$store.state.isLeaveTags == true){
          this.multipleSelection = [];
          this.$refs.mainTable.clearSelection()
          this.$store.state.isLeaveTags = !this.$store.state.isLeaveTags
        }
      }
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss">
  .dialog-mini .el-select {
    width: 100%;
  }
  .data-rule-wrap{
    .ruleSpan{
      cursor: pointer;
      color: #1a96d4;
    }
    .custom-dialog{
      .el-dialog{
        height: 70%;
        .el-dialog__body{
          height: calc(100% - 35px - 40px);
        }
      }
    }
    .custom-card{
      height: 100%;
      .el-card__body{
        height:calc(100% - 34px);overflow: auto;
      }
    }
    .flex-item{
      overflow: hidden;
    }
  }
  .part-box{
    width: 200px;
    border: 1px solid #e4e4e4;
  }
  .table-box{
    border: 1px solid #e4e4e4;
    border-left: 0;
  }
  .rule-group-box{
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
    .rule-groups{
      .rule-head{
        padding: 5px 10px;
        background: #e4e4e4;
        .sellect-operation{
          width: 120px;
        }
      }
      .rule-form{
        padding-bottom: 0;
        border: 1px solid #e4e4e4;
        border-radius: 0 0 0 5px;
        border-top: 0;
        padding-left: 10px;
        padding-right: 10px;
        .el-form{
          padding-top: 0;
        }
      }
    }
    .add-rule-group{
      text-align: center;
      border: 1px solid #e4e4e4;
      border-radius: 0px 5px 5px 0;
      font-size: 60px;
      font-weight: bold;
      padding: 0 30px;
      border-left: 0;
      cursor: pointer;
      color: #999999;
      i:hover{
        color: #4a4b4b;
      }
    }
  }
</style>
<style scoped  rel="stylesheet/scss" lang="scss">
  .custom-theme .special_sjqx ::v-deep .el-form-item,.special_sjqx ::v-deep .el-form-item{
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }
</style>
